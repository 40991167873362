import { UserRole } from "../helpers/authHelper";

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const adminRoot = '/app';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://api.coloredstrategies.com';
export const apiBasePath = 'https://co-driver-backend.adriit.in/api';
// export const apiBasePath = 'http://127.0.0.1:8000/api';
export const loginAPI = `${apiBasePath}/login`;
export const logoutAPI = `${apiBasePath}/logout`;
export const changePasswordAPI = `${apiBasePath}/changePassword`;
export const DriverJobListAPI = `${apiBasePath}/job/getDriverJobList`;
export const DriverEarningHoursAPI = `${apiBasePath}/job/getDriverEarningHours`;
export const JobDetailAPI = `${apiBasePath}/job/getJobDetail`;
export const AcceptJobAPI = `${apiBasePath}/job/acceptJob`;
export const LogJobActivityAPI = `${apiBasePath}/job/logJobActivity`;
export const GetActivityDocumentAPI = `${apiBasePath}/job/getActivityDocument`;

export const currentUser = {
  id: 1,
  title: 'Driver User',
  img: '/assets/img/custom/user_profile.png',
  email : 'driver@gmail.com',
  phone : '8866244200',
  date: 'Last seen today 15:24',
  driver_destination : "",
  role: UserRole.Driver,
  token : ''
}


export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.blueolympic';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
